import {Controller} from 'stimulus'

import {useIntersection} from 'stimulus-use'
import $ from "jquery";
import {fa} from "../../../public/build/vendors~app";

export default class extends Controller {
    connect() {
        this.appId = this.element.dataset.appid;
        this.apiKey = this.element.dataset.apikey;
        this.indexPrefix = this.element.dataset.indexprefix;
        this.currency = this.element.dataset.currency;

        this.isInit = false; // Track initialization state

        $('.algolia-search .toggle-filter').click(function (e) {
            $('.algolia-search #filters').slideToggle(function () {
                $('.algolia-search #filters').toggleClass('active');
            });
            e.preventDefault();
        });

        document.addEventListener("click", (event) => {
            const searchContainer = $(".algolia-search")[0];
            const searchForm = $("#instant-search-form")[0];
            const filters = document.querySelector('#filters');

            if (
                this.isInit &&
                searchContainer &&
                !searchContainer.contains(event.target) &&
                searchForm &&
                !searchForm.contains(event.target) &&
                filters &&
                !filters.contains(event.target) // Новата проверка за филтри
            ) {
                console.log("close");
                this.searchDispose();
            }
        });
    }

    isMobile() {
        return window.matchMedia("(max-width: 767px)").matches; // Adjust max-width as needed
    }

    searchInit() {
        event.preventDefault();

        setTimeout(() => {
            if (!this.isInit) {
                // Create a new search instance
                const searchClient = algoliasearch(this.appId, this.apiKey);

                this.search = instantsearch({
                    indexName: this.indexPrefix,
                    searchClient,
                });

                this.search.addWidgets([
                    instantsearch.widgets.configure({
                        hitsPerPage: 24,
                    }),
                    instantsearch.widgets.searchBox({
                        container: '#searchbox',
                        placeholder: 'Search for products...',
                    }),
                    instantsearch.widgets.hits({
                        container: '#hits',
                        templates: {
                            item: `
                    <div>
                        <a href="{{url}}"><img src="{{image}}" alt="{{name}}" style="width: 100px;" /></a>
                        <a href="{{url}}"><h3>{{name}} {{year}}</h3></a>
                        <div>{{price}}  ${this.currency}</div>
                    </div>
                    `,
                        },
                    }),
                    instantsearch.widgets.pagination({
                        container: '#pagination',
                        scrollTo: '#hits',


                    }),
                    instantsearch.widgets.refinementList({
                        container: '#category-filter',
                        attribute: 'defaultCategory.name',
                        showMore: true,
                    }),
                    instantsearch.widgets.refinementList({
                        container: '#manufacturer-filter',
                        attribute: 'manufacturer.name',
                        showMore: true,
                    }),
                    instantsearch.widgets.refinementList({
                        container: '#variety-filter',
                        attribute: 'wineOptions.defaultVariety.name',
                        showMore: true,
                    }),
                    instantsearch.widgets.refinementList({
                        container: '#country-filter',
                        attribute: 'wineOptions.country.name',
                        showMore: true,
                    }),
                ]);

                this.search.on('render', () => {
                    const offset = 200;
                    const hasResults = document.querySelector('#hits .ais-Hits-item') !== null;

                    const filterContainer = document.querySelector('#filters');
                    if (filterContainer) {
                        if (hasResults) {
                            filterContainer.classList.remove('d-none');
                        } else {
                            filterContainer.classList.add('d-none');
                        }
                    }

                    const paginationContainer = document.querySelector('#pagination');
                    if (paginationContainer) {
                        if (hasResults) {
                            paginationContainer.classList.remove('d-none');
                        } else {
                            paginationContainer.classList.add('d-none');
                        }
                    }

                    const paginationButtons = document.querySelectorAll('#pagination .ais-Pagination-link');
                    paginationButtons.forEach((button) => {
                        button.addEventListener('click', () => {
                            setTimeout(() => {
                                const targetElement = document.querySelector('#hits');
                                const topPosition = targetElement.getBoundingClientRect().top + window.pageYOffset - offset;
                                window.scrollTo({ top: topPosition, behavior: 'smooth' });
                            }, 0);
                        });
                    });
                });


                this.search.start();

                // Show the search container
                const searchContainer = document.querySelector('.algolia-search');
                if (searchContainer) {
                    searchContainer.style.display = 'block'; // Make it visible again
                }

                if (!this.isMobile()) {
                    const filterContainer = document.querySelector('#filters');
                    if (filterContainer) {
                        filterContainer.style.display = 'block'; // Make it visible again
                    }
                }


                // Set focus on the search box
                const searchInput = document.querySelector('#searchbox input');
                if (searchInput) {
                    searchInput.focus();
                }

                this.isInit = true; // Mark as initialized
                document.body.classList.add('instant-open');
            } else {
                // If already initialized, just show the container
                const searchContainer = document.querySelector('.algolia-search');
                if (searchContainer) {
                    searchContainer.style.display = 'block';
                }
                document.body.classList.add('instant-open');
            }

            document.querySelector('#filters').addEventListener('click', (event) => {
                event.stopPropagation();
            });


        }, 100);


    }

    searchDispose() {
        if (this.search) {
            // Stop and dispose of the search instance
            this.search.dispose();

            // Hide the search container
            const searchContainer = document.querySelector('.algolia-search');
            if (searchContainer) {
                searchContainer.style.display = 'none'; // Hide the container
            }

            // Remove any open class or states
            document.body.classList.remove('instant-open');
            this.isInit = false; // Reset initialization flag
        }
    }

    disconnect() {
        if (this.isInit) {
            document.body.classList.remove('instant-open');
        }
    }

}